<template>
	<section class="jmm-page-container">
		<div class="row top-xs start-lg">
			<div class="col-xs-12 col-lg-12">
				<div class="row top-xs start-lg">
					<div class="col-xs-12 col-sm-12 ">
						<div class="page-intro-text">
							<h1>Case Studies</h1>
							<p>Projects showcasing my front-end development and UX design work, driving user-focused solutions at Carroll University.</p>
						</div>	
					</div>
				</div>
			</div>
			<div class="col-xs-12 col-lg-12">
				<div class="row top-xs start-lg jmm-gutter-20">
					<div class="col-xs-12 col-sm-4">
						<div class="jmm-grid-container">
							<router-link class="case-study-cta" to="/case-study/vuejs-grad-filter">
								<img src="/img/case-study/grad-filter-thumb.jpg" alt="">
								<h2>VueJS Graduate Filter Tool</h2>
							</router-link>
						</div>
					</div>
					<div class="col-xs-12 col-sm-4">
						<div class="jmm-grid-container">
							<router-link class="case-study-cta" to="/case-study/kentico-widgets">

								<img src="/img/case-study/widgets-thumb.jpg" alt="">
								<h2>Kentico CMS Widget Development</h2>
							</router-link>
						</div>
					</div>
					<div class="col-xs-12 col-sm-4">
						<div class="jmm-grid-container">
							<router-link class="case-study-cta" to="/case-study/video-vimeo-feature">
								<img src="/img/case-study/video-player-thumb.jpg" alt="">
								<h2>Custom Template Development</h2>
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	
</script>

<style scoped>

</style>