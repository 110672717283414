<template>
	<section class="jmm-page-container">
		<div class="row">
			<div class="col-xs-12 col-sm-12">
				<div class="row">
					<div class="col-xs-12 col-sm-8 last-sm">
						<div class="page-intro-text">
							<h1>Case Study: CMS Widget Development</h1>
							<p>Enhanced an existing library of modular widgets, enabling team members to quickly build responsive, visually engaging pages.</p>
						</div>
					</div>
				
					<div class=" col-xs-12 col-sm-4">
						<div class="cs-overview">
							<h2>The Goal</h2>
							<p><strong>Objective:</strong><br> Empower content managers by building custom designed widgets, enabling them to independently create dynamic, responsive pages and templates.</p>
							<p><strong>Challenge:</strong><br> Develop additional versatile widget components to reinforce brand and simplify content management across 2,000+ pages.</p>
							<p><strong>Tools:</strong><br>
							JavaScript, Asp.net, SASS, Git, UX Design, SEO
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xs-12 col-lg-10 pad-btm-100">
				<div class="row top-xs">
					<div class="first-xs col-xs-12 col-sm-5">
						<figure class="cs-img">
							<img src="/img/case-study/widget-resource-guide-frontend.jpg" alt="Screenshot of widget resource guide displaying custom designed cms widgets." />
							<figcaption>
								Widget library example.
							</figcaption>
						</figure>
					</div>
					<div class="col-xs-12 col-sm-7">
						<h3>Design and Functionality</h3>
						<ul>
							<li><strong>Streamline Design Assembly:</strong> Built custom reusable widgets for content teams, allowing for faster prototyping and team collaboration.</li>
							<li><strong>SEO and Accessibility:</strong> Adhered to best practices for semantic markup and visibility.</li>
							<li><strong>Responsive Design:</strong> Ensured content adapts across devices.</li>
							<li><strong>Simplified User Journeys:</strong> Predefined elements guide users through predictable, user-friendly journeys.</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="col-xs-12 col-lg-10 pad-btm-100">
				<div class="row top-xs start-lg">
					<div class="first-xs last-sm col-xs-12 col-sm-5">
						<figure class="cs-img">
							<img src="/img/case-study/widget-backend.jpg" alt="Screenshot of cms backend interface for editing custom video widget" />
							
							<figcaption>
								Back-end admin panel example.
							</figcaption>
						</figure>
					</div>
					<div class="col-xs-12 col-sm-7">
						<h2>Content Management</h2>
						<ul>
							<li><strong>Dynamic Fields: </strong>Integrated options for content alignment, dynamic CTA fields and clear labels for easy customization and efficient content creation.</li>
							<li><strong>Clear Communication: </strong>Concise, intuitive layout options and field descriptions.</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="col-xs-12 col-lg-10 pad-btm-100">
				<div class="row top-xs">
					<div class="col-xs-12 col-sm-7">
						<h2>Results</h2>
						<p>This project enhanced an existing CMS initiative by adding modular widgets, enabling users to create responsive, visually engaging pages with greater flexibility and efficiency.</p>
					</div>
					<div class="first-xs col-xs-12 col-sm-5">
						<figure class="cs-img">
							<img src="/img/case-study/widget-library-workflow.jpg" alt="Screenshot of web publishing workflow diagram." />
							<figcaption>
								Publishing workflow diagram.
							</figcaption>
						</figure>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	
</script>

<style scoped>
</style>