<template>
	<section class="jmm-footer row top-xs start-xs">
		<div class="container col-xs-12">
			<p class="jmm-sm-text">© {{ currentYear }} Jacob Mallach. All rights reserved.</p>
		</div>
	</section>
</template>

<script>
	export default {
		name: "footerInc",
		computed:{
			currentYear(){
				return new Date().getFullYear();
			}
		}
	};
</script>
<style>

</style>