<template>
	<section class="jmm-page-container">
		<div class="row">
			<div class="col-xs-12 col-sm-12">
				<div class="row">
					<div class="col-xs-12 col-sm-8 last-sm">
						<div class="page-intro-text">
							<h1>Case Study: Homepage Video Player</h1>
							<p>The homepage template was enhanced with a Vimeo video player and the option to switch to a static image, designed to boost user engagement and strengthen brand presence.</p>
						</div>
					</div>
				
					<div class=" col-xs-12 col-sm-4">
						<div class="cs-overview">
							<h2>The Goal</h2>
							<p><strong>Objective:</strong><br> Modify the homepage template to integrate a Vimeo video player with the option to switch to a static image.</p>
							<p><strong>Challenge:</strong><br> Implement a video player with strong design, manageability, responsiveness and SEO.</p>
							<p><strong>Tools:</strong><br>
							JavaScript, Asp.net, SASS, Git
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xs-12 col-lg-10 pad-btm-100">
				<div class="row top-xs ">	
					<div class="first-xs col-xs-12 col-sm-5">
						<figure class="cs-img">
							<img src="/img/case-study/custom-templates-homepage-frontend-tablet.jpg" alt="Screenshot of above the fold content for client homepage design"/>
							<figcaption>
								Homepage Video Element Example.
							</figcaption>
						</figure>
					</div>
					<div class="col-xs-12 col-sm-7">

						<h3>Design and Functionality</h3>
						<ul>
							<li><strong>Responsive Design <Design></Design>:</strong> Fluidly adapts to all screen sizes for a consistent user experience.</li>
							<li><strong>Immersive Coverage:</strong> Expands to fill the browser window, enhancing user engagement.</li>
							<li><strong>SEO & Accessibility:</strong> Adheres to best practices for visibility and inclusivity.</li>
							<li><strong>Autoplay Functionality:</strong> Ensures seamless user interaction aligned with UX goals.</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="col-xs-12 col-lg-10 pad-btm-100">
				<div class="row top-xs start-lg">
					<div class="first-xs last-sm col-xs-12 col-sm-5">
						<figure class="cs-img">
							<img src="/img/case-study/custom-templates-homepage-backend.jpg" alt="Screenshot of back-end homepage input fields." />
							<figcaption>
								Back-end admin panel example.
							</figcaption>
						</figure>
					</div>
					<div class="col-xs-12 col-sm-7">
						<h3>Content Management</h3>
						<ul>
							<li><strong>Dynamic Fields: </strong>Enabled easy media updates via CMS for non-technical users.</li>
							<li><strong>Clear Communication: </strong>Concise, intuitive options and field descriptions.</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="col-xs-12 col-lg-10">
				<div class="row top-xs ">
					<div class="first-xs col-xs-12 col-sm-8">
						<div class="cs-result">
							<h3>Results</h3>
							<p>
								Delivered a dynamic, user-friendly homepage experience that met all technical and UX requirements while ensuring ease of maintenance and accessibility.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	
</script>

<style scoped>
	
</style>