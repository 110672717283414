<template>
	<section class="white-bg jmm-header row top-xs start-xs">
		<div class="col-xs-12">
			<ul class="global-nav row">
				<li class="col-xs-2 col-sm-1"><router-link to="/">Home</router-link></li>
				<li class="col-xs-5 col-sm-2"><router-link to="/case-study">Case Studies</router-link></li>
			</ul>
		</div>
	</section>
</template>

<script>
	export default {
		name: "headerInc"
	};
</script>

<style>
	
</style>
