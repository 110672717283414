import { createWebHashHistory, createRouter } from 'vue-router'
import Home from '../components/home'
import CaseStudies from '../components/case-studies'
import VideoVimeoFeature from '../components/case-study/video-vimeo-feature'
import KenticoWidgets from '../components/case-study/kentico-widgets'
import VueGradFilter from '../components/case-study/vuejs-grad-filter'

const routes = [
	{
		path: "/",
		component: Home,
	},
	{
		path: "/case-study",
		component: CaseStudies,
	},
	{
		path: "/case-study/video-vimeo-feature",
		component: VideoVimeoFeature,
	},
	{
		path: "/case-study/kentico-widgets",
		component: KenticoWidgets,
	},
	{
		path: "/case-study/vuejs-grad-filter",
		component: VueGradFilter,
	}
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});

export default router;