<template>
	<section class="jmm-page-container">
		<div class="row top-xs start-lg">
			<div class="col-xs-12 col-lg-10">
				<div class="row top-xs start-lg">
					<div class="col-xs-12 col-sm-12 ">
						<div class="home-intro-text">
							<h1>Hello,<br/> I'm Jacob.</h1>
						</div>
					</div> 
				</div>
			</div>
			<div class="col-xs-12 col-lg-10">
				<div class="row top-xs start-lg">
					<div class="col-xs-12 col-sm-8">
						<div class="home-intro-text">
							<p>I'm a <a class="mint-text" href="/docs/jmallach-fed-resume.pdf" rel="noreferrer nofollow" >client-side developer</a> who transforms ideas into intuitive, user-focused designs. Find me on <a class="mint-text" rel="noreferrer nofollow" href="https://github.com/jicub">github</a>. I wear lots of hats. I'm a dad, <a class="mint-text" rel="noreferrer nofollow" href="https://highlonesomepencils.com/">illustrator</a> and <a class="mint-text" rel="noreferrer nofollow" href="https://www.instagram.com/barredowlstringband/">music maker</a>. Ring my electronic bell at <span class="mint-text">illo[at]jacobmallach.com</span>.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	
</script>

<style scoped>
	
	
</style>