import { createApp } from 'vue'
import router from './router'
import App from './App.vue'
import FlexboxGrid from './assets/css/flexboxgrid.min.css'
import BoilerPlate from './assets/css/boiler-plate.css'
import GlobalStyles from"./assets/css/sass/_global.scss";

const carrolluFilterApp = createApp(App)

carrolluFilterApp.use(router).use(FlexboxGrid).use(BoilerPlate).use(GlobalStyles)

carrolluFilterApp.mount('#app')



