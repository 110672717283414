<template>
	<section class="jmm-page-container">
		<div class="row top-xs start-lg">
			<div class="col-xs-12 col-sm-12">
				<div class="row">
					<div class="col-xs-12 col-sm-8 last-sm">
						<div class="page-intro-text">
							<h1>Case Study: Grad Filter Tool</h1>
							<p>A user-centric VueJS filtering tool was developed to enhance exploration of the graduate programs offered by the university. The tool allows prospective students to effortlessly discover programs that align with their academic and professional aspirations.</p>
						</div>
					</div>
					<div class=" col-xs-12 col-sm-4">
						<div class="cs-overview">
							<h2>The Goal</h2>
							<p><strong>Objective:</strong><br> Simplify graduate program navigation to boost engagement and streamline decisions for prospective students.</p>
							<p><strong>Challenge:</strong><br> Redesigned a static page with 30 anchor links into an intuitive, dynamic tool that simplifies navigation and improves the UX across devices.</p>
							<p><strong>Tools:</strong><br>
							VueJS, REST, SASS, Git
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xs-12 col-lg-10 pad-btm-100">
				<div class="row top-xs ">	
					<div class="first-xs col-xs-12 col-sm-5">
						<figure class="cs-img">
							<img src="/img/case-study/grad-filter-frontend-desktop.jpg" alt="Screenshot of desktop design for filter tool showing navigation in left column and filter results in right column." />
							
							<figcaption>
								Desktop design example.
							</figcaption>
						</figure>
					</div>
					<div class="col-xs-12 col-sm-7">

						<h3>Design and Functionality</h3>
						<ul>
							<li><strong>Dynamic Filtering:</strong> Real-time sorting for instant results.</li>
							<li><strong>Interactive CTAs:</strong> Direct access to program details and resources.</li>
							<li><strong>Responsive Design:</strong> Optimized for all devices, ensuring accessibility and usability for a wide audience.</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="col-xs-12 col-lg-10 pad-btm-100">
				<div class="row top-xs start-lg">
					<div class="first-xs last-sm col-xs-12 col-sm-5">
						<figure class="cs-img">
							<img src="/img/case-study/grad-filter-sql-custom-table-backend-items.jpg" alt="Screenshot of desktop design for filter tool admin panel that shows data in a table format for user editing." />
							<figcaption>
								Backend admin panel example.
							</figcaption>
						</figure>
					</div>
					<div class="col-xs-12 col-sm-7">
						<h3>Managing the Data</h3>
						<ul>
							<li><strong>Efficient Content Management:</strong> Empowered staff with intuitive tools for maintaining content without technical expertise.</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="col-xs-12 col-lg-10 pad-btm-100">
				<div class="row top-xs start-lg">
					<div class="first-xs col-xs-12 col-sm-5">
						<figure class="cs-img">
							<img src="/img/case-study/grad-filter-frontend-mobile-nav-open-selected.jpg" alt="Screenshot of small screen design for filter tool showing compact navigation with dropdown menu filter." />

							<figcaption>
								Mobile design example.
							</figcaption>
						</figure>
					</div>
					<div class="col-xs-12 col-sm-7">
						<h3>Small Screens</h3>
						<ul>
							<li><strong>Mobile Optimization:</strong> Compact, user-friendly design with clear visual feedback.</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="col-xs-12 col-lg-10">
				<div class="row top-xs ">
					<div class="col-xs-12 col-sm-5 last-sm">
						<figure class="cs-img">
							<img src="/img/case-study/grad-filter-heat-map.jpg" alt="Screenshot of heat map showing heavy engagement with filter menu." />
							<figcaption>
								Heat map of user engagement activity.
							</figcaption>
						</figure>
					</div>
					<div class="col-xs-12 col-sm-7">
						<div class="cs-result">
							<h3>Results</h3>
							<p>
								The Graduate Program Filter Tool improved user engagement and exploration:
							</p> 
							<ul>
								<li><strong>Increased Engagement Time:</strong> Average user engagement times increased by 68%, reflecting deeper interaction and meaningful exploration.</li>

								<li><strong>Boosted Interest:</strong> Total page views climbed from 45,000 to 49,000, signaling heightened user activity.</li>
								<li><strong>Consistent User Growth:</strong> Attracted 15,000 active users, a steady increase from 14,700 the prior year.</li>
							</ul>

							<p>These results communicate the power of user-centered design and intuitive navigation to drive both quantitative growth and a more satisfying user experience.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	
</script>

<style scoped>
</style>